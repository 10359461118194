import React from 'react';
import './index.css';
import backgroundImage from './keyboard.webp';

function App() {
  return (
    <div
      className="min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <header className="absolute top-0 left-0 p-4">
        <h1 className="text-white text-4xl font-bold">STELLAR VIRAL</h1>
      </header>
      <footer className="absolute bottom-0 left-0 p-4">
        <p className="text-white text-3xl font-thin">
          We’re experimenting, building, and incubating new technical and creative projects.
        </p>
      </footer>
    </div>
  );
}

export default App;
